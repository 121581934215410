import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Form, Table } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const StoricoTab = () => {
    const location = useLocation();
    const dataLista = location.state?.lista;
    const nomeAzienda = 'Pizzeria Rossi'
  
  const [listaPrenotazioni, setListaPrenotazioni] = useState([])

  useEffect(() => {

    setListaPrenotazioni([])

    for(var i = 0; i < dataLista.length; i++){
        const prenotazione = dataLista?.[i];
            const salva = {
            ...prenotazione
            };
            setListaPrenotazioni(lista => [...lista, salva])
    }
  }, []);


  return (
    <Form style={{paddingTop:'25px', width:'100%'}}>
        <h1 className="my-4 text-center" >{nomeAzienda}</h1>
        <Row>
            <Col md={12} className="px-5">
                <h4>Storico Prenotazione</h4>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Id Prenotazione</th>
                            <th>Richiedente</th>
                            <th>Posti</th>
                            <th>Data Prenotazione</th>
                            <th>Orario Prenotazione</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaPrenotazioni.length > 0 ? (
                            listaPrenotazioni.map((prenotazione, index) => (
                                <tr key={index}>
                                    <td>{prenotazione.idPrenotazione}</td>
                                    <td>{prenotazione.richiedente}</td>
                                    <td>{prenotazione.posti}</td>
                                    <td>{prenotazione.dataPrenotazione}</td>
                                    <td>{prenotazione.orarioPrenotazione}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">Nessuna prenotazione per la data selezionata</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Form>
  );
};

export default StoricoTab;
