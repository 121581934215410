import React , {useState} from "react";
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { inviaLogin, inviaRegistrazione, listaPrenotazioni, infoAzienda } from '../utils/Chiamate'
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ClipLoader } from 'react-spinners';
import '../App.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { SIDEBAR_OPTION } from '../utils/Constants';

function Client({ isRegister }) {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data;
    const [loading, setLoading] =  useState(false);
    const [formDataLogin, setFormDataLogin] = useState({
        email: '',
        password: ''
    })
    const [formDataRegistrazione, setFormRegistrazione] = useState({
        nomeAzienda: '',
        indirizzo: '',
        citta: '',
        telefono: '',
        email: '',
        emailAziendale: '',
        dominio: ''
    })

    const handleChangeLogin = (e) => {
        const {name, value} = e.target;
        setFormDataLogin({...formDataLogin, [name]: value});
    }

    const handleChangeRegistrazione = (e) => {
        const {name, value} = e.target;
        setFormRegistrazione({...formDataRegistrazione, [name]: value});
    }
    
    const login = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await inviaLogin(formDataLogin)
            if (result) { 
                let infoJson = result?.configurazione?.body;
                const lista = await listaPrenotazioni(infoJson?.anagrafica?.idAzienda);
                const info = await infoAzienda(infoJson?.anagrafica?.idAzienda);
                setLoading(false);
                navigate('/dashboard', { state: { lista, section: SIDEBAR_OPTION.PRENOTAZIONI, info } }); 
            } else {
                setLoading(false);
                alert('Login fallito, controlla le credenziali'); 
            }
        } catch (error) {
        }
    }

    const registrazione = async (e) => {
        e.preventDefault();
        try {
            const result = await inviaRegistrazione(formDataRegistrazione)
        } catch (error) {
        }
    }

    const goToRegister = () => {
        navigate('/registrazione'); 
    };

    const goToLogin = () => {
        navigate('/login');
    };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#f7d3cc' }}>
        <Row className="bg-white rounded shadow-lg overflow-hidden" style={{ maxWidth: '1100px', width:'90%' }}>
            <Col xs={12} md={5} className="d-flex flex-column align-items-center justify-content-center text-center p-5" style={{ backgroundColor: '#a48c7e', color: 'white' }}>
                <img src={'https://www.racinaristorante.it/wp-content/uploads/2024/05/header-prenota.webp'} alt="Home" className="img-fluid rounded-circle mb-4" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                <h2 className="font-weight-bold font-link">Benvenuti</h2>
            </Col>

            {   !isRegister &&
                <Col xs={12} md={7} className='p-5'>
                    <h2 className="mb-4 text-center font-link">Sign Up</h2>
                    <Form onSubmit={login}>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label className="font-link">Email</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={formDataLogin.email}
                                onChange={handleChangeLogin}
                                required
                                className="w-100"
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mb-3" style={{paddingBottom: '50px'}}>
                            <Form.Label className="font-link">Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formDataLogin.password}
                                onChange={handleChangeLogin}
                                required
                                className="w-100"
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-center gap-4 mt-4">
                            <Button type="submit" variant="primary">
                                Login
                            </Button>
                            <Button variant="secondary" onClick={goToRegister}>
                                Registrati
                            </Button>
                        </div>
                    </Form>
                </Col>
            }

            {   isRegister &&
                <Col xs={12} md={7} className='p-4'>
                    <h2 className="mb-4 text-center font-link">Sign In</h2>
                    <Form onSubmit={registrazione}>
                        <Row className="mb-4">
                            <Col md={6}>
                                <Form.Group controlId="formNomeAzienda" className="mb-3">
                                    <Form.Label className="font-link">Nome Azienda</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomeAzienda"
                                        placeholder="Nome Azienda"
                                        value={formDataRegistrazione.nomeAzienda}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formCitta" className="mb-3">
                                    <Form.Label className="font-link">Citta</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="citta"
                                        placeholder="Citta"
                                        value={formDataRegistrazione.citta}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col md={6}>
                                <Form.Group controlId="formIndirizzo" className="mb-3">
                                    <Form.Label className="font-link">Indirizzo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="indirizzo"
                                        placeholder="Indirizzo"
                                        value={formDataRegistrazione.indirizzo}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formCitta" className="mb-3">
                                    <Form.Label className="font-link">Telefono</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        name="telefono"
                                        placeholder="Numero di telefono"
                                        value={formDataRegistrazione.telefono}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col md={6}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label className="font-link">Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        value={formDataRegistrazione.email}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label className="font-link">Email Aziendale</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="emailAziendale"
                                        placeholder="Email Aziendale"
                                        value={formDataRegistrazione.emailAziendale}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row className="mb-4">
                            <Col>
                                <Form.Group controlId="formDominio" className="mb-3">
                                    <Form.Label className="font-link">Sito internet</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="dominio"
                                        placeholder="Inserire proprio sito internet"
                                        value={formDataRegistrazione.dominio}
                                        onChange={handleChangeRegistrazione}
                                        required
                                        className="w-100"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-center gap-4">
                            <Button variant="secondary" onClick={goToLogin}>
                                Login
                            </Button>
                            <Button type="submit" variant="primary">
                                Registrati
                            </Button>
                        </div>
                    </Form>
                </Col>
            }

            {
                loading &&
                <div className="loading">
                    <ClipLoader color="#000" size={200} />
                </div>
            }
        </Row>
    </Container>
  );
}

export default Client;