import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import {configurazioneAzienda, salvaConfigurazione} from '../utils/Chiamate'
import { useLocation } from "react-router-dom";
import { Button, Form, Row, Container } from 'react-bootstrap';

function ConfigurazioneTab() {
    const location = useLocation();
    const dataInfo = location.state?.info;

    const [loading, setLoading] =  useState(false);
    const [config, setConfig] = useState({
        idAzienda: '',
        posti: '',
        giorniChiusura: '',
        orario: '',
        logo: '',
        ferie: ''
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        setConfig({...config, [name]: value});
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await configurazioneAzienda(dataInfo?.anagrafica?.idAzienda);
                if(data?.configurazione){
                    setConfig(data);
                } else {
                    setConfig(prevConfig  => ({...prevConfig, idAzienda: dataInfo?.anagrafica?.idAzienda}));
                }
            } catch (error) {
            } 
        };
    
        fetchData();

    }, []);

    const configurazione = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await salvaConfigurazione(config)
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
        <Form onSubmit={configurazione} >
        <h2 className="mb-4 text-center font-link">Configurazioni</h2>
        <Row>
            <Form.Group controlId="formRichiedente" className="mb-3">
                <Form.Label className="font-link">Posti disponibili</Form.Label>
                <Form.Control
                    type="number"
                    name="posti"
                    placeholder= "Inserisci numero posti"
                    value={config.posti}
                    onChange={handleChange}
                    className="w-100"
                />
            </Form.Group>
        </Row>
        <Row>
            <Form.Group controlId="formData" className="mb-3">
                <Form.Label className="font-link" style={{marginRight:'50px'}}>Giorni chiusura</Form.Label>
                <select value={config.giorniChiusura}
                    name='giorniChiusura'
                    onChange={handleChange}
                    className="form-control w-100 font-link" required>
                    <option value=''>Seleziona un giorno</option>
                    <option value='Lunedì'>Lunedì</option>
                    <option value='Martedì'>Martedì</option>
                    <option value='Mercoledì'>Mercoledì</option>
                    <option value='Giovedì'>Giovedì</option>
                    <option value='Venerdì'>Venerdì</option>
                    <option value='Sabato'>Sabato</option>
                    <option value='Domenica'>Domenica</option>
                </select>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group controlId="formOrario" className="mb-3">
                <Form.Label className="font-link">Orario chiusura</Form.Label>
                <Form.Control
                    type="text"
                    name="orario"
                    placeholder= "Inserisci orario chiusura"
                    value={config.orario}
                    onChange={handleChange}
                    className="w-100"
                />
            </Form.Group>
        </Row>
            <div className="d-flex justify-content-center mt-4">
                <Button type="submit" variant="primary" disabled={loading}>
                    {loading ? "Invio..." : "Invia Prenotazione"}
                </Button>
            </div>
        </Form>
    </Container>
  );
}

export default ConfigurazioneTab;