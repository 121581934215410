import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Form, Table} from 'react-bootstrap';
import { FaRegCalendarAlt } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import { it } from "date-fns/locale";
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const PrenotazioniTab = () => {
    const location = useLocation();
    const dataLista = location.state?.lista;
    const dataInfo = location.state?.info;

    const nomeAzienda = dataInfo?.anagrafica?.nomeAzienda;
    const giornoChiusura = dataInfo?.configurazione?.giorniChiusura
  
  const [dataSelezionata, setDataSelezionata] = useState((new Date().getDay() === giornoChiusura ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date()))
  const [listaPrenotazioni, setListaPrenotazioni] = useState([])

  useEffect(() => {
    const oggiFormatted = dataSelezionata.toISOString().split('T')[0];

    setListaPrenotazioni([])

    for(var i = 0; i < dataLista.length; i++){
        const prenotazione = dataLista?.[i];
        if(prenotazione?.dataPrenotazione === oggiFormatted){
            const salva = {
            ...prenotazione
            };
            setListaPrenotazioni(lista => [...lista, salva])  
        }
    }
  }, [dataSelezionata]);

  return (
    <Form style={{paddingTop:'25px', width:'100%'}}>
        <h1 className="my-4 text-center" >{nomeAzienda}</h1>
        <Row>
            <Col md={12} className="px-5 pb-4">
                <Form.Group controlId="formDate">
                    <Form.Label  style={{marginRight:'50px', whiteSpace:'nowrap'}}>Seleziona Data</Form.Label>
                    <DatePicker 
                        selected={dataSelezionata} 
                        onChange={(date) => setDataSelezionata(date)}
                        dateFormat='dd/MM/yyyy'
                        minDate={new Date()}
                        className="form-control w-100"
                        filterDate={(date) => date.getDay() !== parseInt(giornoChiusura)}
                        locale={it}
                        showIcon
                        customInput={<CustomInput />}
                        required
                    />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={12} className="px-5">
                <h4>Lista Prenotazione</h4>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Id Prenotazione</th>
                            <th>Richiedente</th>
                            <th>Posti</th>
                            <th>Orario Prenotazione</th>
                            <th>Annulla prenotazione</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaPrenotazioni.length > 0 ? (
                            listaPrenotazioni.map((prenotazione, index) => (
                                <tr key={index}>
                                    <td>{prenotazione.idPrenotazione}</td>
                                    <td>{prenotazione.richiedente}</td>
                                    <td>{prenotazione.posti}</td>
                                    <td>{prenotazione.orarioPrenotazione}</td>
                                    <td></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">Nessuna prenotazione per la data selezionata</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Form>
  );
};


const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input 
        type="text" 
        className="form-control"
        value={value}
        onClick={onClick}
        placeholder="Scegli una data"
      />
      <span className="input-group-text" onClick={onClick}>
        <FaRegCalendarAlt />
      </span>
    </div>
  );

export default PrenotazioniTab;
