import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import Sidebar from '../utils/Sidebar'
import { Container, Card } from 'react-bootstrap';

function RistoranteHome() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  return (
    <Container fluid className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f7d3cc' }}>
      {
        !isMobile &&
        <Card className="bg-white rounded shadow-lg overflow-hidden" style={{ width: '90%', marginTop: '5%' }}>
          <Sidebar isMobile={isMobile}/>
        </Card>
      }
      {
        isMobile &&
        <Sidebar isMobile={isMobile}/>
      }
        
    </Container>
  );
}

export default RistoranteHome;