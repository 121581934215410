import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {configurazioneAzienda} from '../utils/Chiamate'

const Home = () => {
    const navigate = useNavigate();
    const { id } = useParams();
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await configurazioneAzienda(id);
  
          navigate('/info-prenotazione', { state: { data } });
        } catch (error) {
        } 
      };
  
      fetchData();
    }, [navigate]);
  
    return (
      <div>
        <h1>Caricamento...</h1>
      </div>
    );
  };
  
  export default Home;