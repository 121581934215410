import React , {useState} from "react";
import { Container, Modal, Button, Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { it } from "date-fns/locale";
import { FaRegCalendarAlt } from "react-icons/fa";
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import {inviaPrenotazione} from '../utils/Chiamate'
import { useLocation } from 'react-router-dom';

const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input 
        type="text" 
        className="form-control"
        value={value}
        onClick={onClick}
        placeholder="Scegli una data"
      />
      <span className="input-group-text" onClick={onClick}>
        <FaRegCalendarAlt />
      </span>
    </div>
  );

function Prenotazioni() {
    const location = useLocation();
    const data = location.state?.data;

    const [dataSelezionata, setDataSelezionata] = useState((new Date().getDay() === 1 ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date()))
    const [orarioSelezionato, setOrarioSelezionato] = useState('')
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        cognome: '',
        tel: '',
        email: '',
        persone: ''
    })

    const prenotazione = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        try {
            const result = await inviaPrenotazione(data.nomeAzienda, formData.cognome, dataSelezionata, orarioSelezionato, formData.tel, formData.email, formData.persone)
            setMessage('Prenotazione effettuata con successo')
            setShowModal(true);
            clearFormData();
        } catch (error) {
            setMessage('Si è verificato un errore. Riprova!');
            setShowModal(true);
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const clearFormData = () => {
        setFormData({
            cognome: '',
            tel: '',
            email: '',
            persone: ''
        });
        setDataSelezionata((new Date().getDay() === 1 ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date()))
        setOrarioSelezionato('')
    }
    

  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#f7d3cc' }}>
        <Row className="bg-white rounded shadow-lg overflow-hidden" style={{ maxWidth: '900px' }}>
            <Col xs={12} md={4} className="d-flex flex-column align-items-center justify-content-center text-center p-4" style={{ backgroundColor: '#a48c7e', color: 'white' }}>
                <img src={data.img} alt="Ristorante" className="img-fluid rounded-circle mb-4" style={{ width: '200px' }} />
                <h2 className="font-weight-bold font-link">Benvenuto all'{data.nomeAzienda}</h2>
            </Col>

            <Col xs={12} md={8} className='p-4'>
                <h2 className="mb-4 text-center font-link">Prenota il tuo tavolo</h2>
                <Form onSubmit={prenotazione}>
                    <Form.Group controlId="formRichiedente" className="mb-3">
                        <Form.Label className="font-link">Cognome</Form.Label>
                        <Form.Control
                            type="text"
                            name="cognome"
                            placeholder="Inserisci il cognome"
                            value={formData.cognome}
                            onChange={handleChange}
                            required
                            className="w-100"
                        />
                    </Form.Group>

                    <Form.Group controlId="formData" className="mb-3">
                        <Form.Label className="font-link" style={{marginRight:'50px'}}>Seleziona una data</Form.Label>
                        <DatePicker selected={dataSelezionata} 
                            onChange={(date) => setDataSelezionata(date)}
                            dateFormat='dd/MM/yyyy'
                            minDate={new Date()}
                            className="form-control w-100"
                            filterDate={(date) => date.getDay() !== data.idChiusura}
                            locale={it}
                            showIcon
                            customInput={<CustomInput />}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formOrario" className="mb-3">
                        <Form.Label className="font-link">Seleziona un orario</Form.Label>
                        <select value={orarioSelezionato}
                            onChange={(ora) => setOrarioSelezionato(ora.target.value)}
                            className="form-control w-100 font-link" required>
                            <option value=''>Seleziona un orario</option>
                            <option value='20:00'>20:00</option>
                            <option value='20:30'>20:30</option>
                            <option value='21:00'>21:00</option>
                            <option value='21:30'>21:30</option>
                            <option value='22:00'>22:00</option>
                            <option value='22:30'>22:30</option>
                        </select>
                    </Form.Group>

                    <Form.Group controlId="formTelefono" className="mb-3">
                        <Form.Label>Numero di telefono</Form.Label>
                        <Form.Control
                            type="tel"
                            name="tel"
                            placeholder="Inserisci il numero di telefono"
                            value={formData.tel}
                            onChange={handleChange}
                            required
                            className="w-100"
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Inserisci l'email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-100"
                        />
                    </Form.Group>

                    <Form.Group controlId="formNumeroPersone" className="mb-3">
                        <Form.Label>Persone</Form.Label>
                        <Form.Control
                            type="number"
                            name="persone"
                            placeholder="Numero persone"
                            value={formData.persone}
                            onChange={handleChange}
                            onInput={(e) => {
                                if (e.target.value.length > 3) {
                                    e.target.value = e.target.value.slice(0, 3);
                                }
                            }}
                            required
                            className="w-100"
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-center mt-4">
                        <Button type="submit" variant="primary" disabled={loading}>
                            {loading ? "Invio..." : "Invia Prenotazione"}
                        </Button>
                    </div>
                </Form>
            </Col>
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Esito Prenotazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className="text-center mb-4">{message}</h4>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Chiudi
                </button>
            </Modal.Footer>
        </Modal>
    </Container>
  );
}

export default Prenotazioni;