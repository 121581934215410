import React, { useState, useEffect } from "react";
import { TbPencilCheck, TbHistoryToggle, TbSettings  } from "react-icons/tb";
import { SIDEBAR_OPTION } from '../utils/Constants';
import PrenotazioniTab from "../ristoranti/PrenotazioniTab";
import StoricoTab from "../ristoranti/StoricoTab";
import ConfigurazioneTab from "../ristoranti/ConfigurazioneTab";
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const Sidebar = (isMobile) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [tab, setTab] = useState(SIDEBAR_OPTION.PRENOTAZIONI)

  return (
    <div className="d-flex">
      {/* Sidebar */}
      <div
        className="vh-100"
        style={{
          width: isExpanded ? "250px" : "90px",
          transition: "width 0.3s",
          backgroundColor: '#a48c7e', 
          color: 'white' 
        }}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <div className="p-3">
          <h4 className="text-center" style={{ display: isExpanded ? "block" : "none" }}>
            Menu
          </h4>
        </div>
        <ul className="nav flex-column p-3">
          <li className="nav-item">
            <div className={`nav-link d-flex align-items-center ${tab === SIDEBAR_OPTION.PRENOTAZIONI ? 'active' : 'text-white'}`} style={{cursor: 'pointer'}} 
            onClick={() => setTab(SIDEBAR_OPTION.PRENOTAZIONI)}>
              <span className="me-2"><TbPencilCheck style={{ fontSize: "24px" }}/></span>
              <span style={{ display: isExpanded ? "inline" : "none" }}>
                Prenotazioni
              </span>
            </div>
          </li>
          <li className="nav-item">
            <div className={`nav-link d-flex align-items-center ${tab === SIDEBAR_OPTION.STORICO ? 'active' : 'text-white'}`} style={{cursor: 'pointer'}} 
            onClick={() => setTab(SIDEBAR_OPTION.STORICO)}>
              <span className="me-2"><TbHistoryToggle style={{ fontSize: "24px" }}/></span>
              <span style={{ display: isExpanded ? "inline" : "none" }}>
                Storico
              </span>
            </div>
          </li>
          <li className="nav-item">
            <div className={`nav-link d-flex align-items-center ${tab === SIDEBAR_OPTION.CONFIGURAZIONE ? 'active' : 'text-white'}`} style={{cursor: 'pointer'}} 
            onClick={() => setTab(SIDEBAR_OPTION.CONFIGURAZIONE)}>
              <span className="me-2"><TbSettings style={{ fontSize: "24px" }}/></span>
              <span style={{ display: isExpanded ? "inline" : "none" }}>
                Configurazione
              </span>
            </div>
          </li>
        </ul>
      </div>

      {
        tab === SIDEBAR_OPTION.PRENOTAZIONI &&
        <PrenotazioniTab />
      }
      {
        tab === SIDEBAR_OPTION.STORICO &&
        <StoricoTab />
      }
      {
        tab === SIDEBAR_OPTION.CONFIGURAZIONE &&
        <ConfigurazioneTab />
      }
    </div>
  );
};

export default Sidebar;
