import nuovaPrenotazioneMock from '../mock/nuovaPrenotazione.json'
import listaPrenotazioniMock from '../mock/listaPrenotazioni.json'
import infoAziendaMock from '../mock/infoAzienda.json'
import loginMock from '../mock/loginMock.json'

const isMocked = false;

const API_URL = "https://bookingsmart-be.onrender.com";

const mapGiorno = (response) => {
    switch(response?.giornoChiusura){
        case 'Lunedì':
            response.idChiusura = 1;
            break;
        case 'Martedì':
            response.idChiusura = 2;
            break;
        case 'Mercoledì':
            response.idChiusura = 3;
            break;
        case 'Giovedì':
            response.idChiusura = 4;
            break;
        case 'Venerdì':
            response.idChiusura = 5;
            break;
        case 'Sabato':
            response.idChiusura = 6;
            break;
        case 'Domenica':
            response.idChiusura = 0;
            break;
        default:
            response.idChiusura = null;
    }
}

export const configurazioneAzienda = async (id) => {
    if(isMocked){
        const response = nuovaPrenotazioneMock;
        mapGiorno(response);
        return response
    } else {
        const response = await fetch(`${API_URL}/configurazione_azienda?idAzienda=${id}`, {
            method: 'GET'
        });
        mapGiorno(response);
        return response.json();
    }
}

export const inviaPrenotazione = async (nomeAzienda, richiedente, dataPrenotazione, orarioPrenotazione, telefono, email, persone) => {
    if(isMocked){

    } else {
        try {
            const response = await fetch(`${API_URL}/salva_prenotazioni`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({nomeAzienda, richiedente, dataPrenotazione, orarioPrenotazione, telefono, email, persone})
            });
    
            if(!response.ok){
                throw new Error('Errore nella risposta del server')
            }
        } catch (error) {
            console.error('Errore durante la prenotazione: ', error)
        }
    }
}

export const inviaLogin = async (data) => {
    if(isMocked){
        return loginMock
    } else {
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });


            if(!response.ok){
                return null;
            }
            return response.json();
        } catch (error) {
            console.error('Errore durante la prenotazione: ', error)
        }
    }
}

export const inviaRegistrazione = async (data) => {
    if(isMocked){
    } else {
        try {
            const response = await fetch(`${API_URL}/salva_anagrafica`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
    
            if(!response.ok){
                throw new Error('Errore nella risposta del server')
            }
        } catch (error) {
            console.error('Errore durante la prenotazione: ', error)
        }
    }
}

export const listaPrenotazioni = async (data) => {
    if(isMocked){
        return listaPrenotazioniMock;
    } else {
        const url = `${API_URL}/prenotazioni?idAzienda=${encodeURIComponent(data)}`
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if(!response.ok){
                throw new Error('Errore nella risposta del server')
            }else {
                return response.json();
            }
        } catch (error) {
            console.error('Errore lista prenotazioni: ', error)
        }
    }
}


export const infoAzienda = async (data) => {
    if(isMocked){
        return infoAziendaMock;
    } else {
        const url = `${API_URL}/recupera_info_azienda?idAzienda=${encodeURIComponent(data)}`
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if(!response.ok){
                throw new Error('Errore nella risposta del server')
            }else {
                return response.json();
            }
        } catch (error) {
            console.error('Errore lista prenotazioni: ', error)
        }
    }
}

export const salvaConfigurazione = async (data) => {
    if(isMocked){

    } else {
        try {
            const response = await fetch(`${API_URL}/salva_configurazione?configurazione=${encodeURIComponent(JSON.stringify(data))}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
    
            if(!response.ok){
                throw new Error('Errore nella risposta del server')
            }
        } catch (error) {
            console.error('Errore durante la prenotazione: ', error)
        }
    }

}
