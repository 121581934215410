import './App.css';
import Prenotazioni from './clienti/Prenotazioni';
import Client from './ristoranti/Client';
import RistoranteHome from './ristoranti/RistoranteHome'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './clienti/Home'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<Home />} />
        <Route path="/login" element={<Client isRegister={false}/>} />
        <Route path="/registrazione" element={<Client isRegister={true}/>} />
        <Route path="/dashboard" element={<RistoranteHome />} />
        <Route path="/info-prenotazione" element={<Prenotazioni />} />
      </Routes>
    </Router>
  );
}

export default App;
